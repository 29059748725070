import React from "react";
import HeroSection from "../components/HeroSection";
import Footer from "../components/Footer";

const Home = () => {
    return (
        <>
            <div className="main">
                <HeroSection />
            </div>
            <Footer />

        </>
    );
};

export default Home;