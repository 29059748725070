import React from "react";
import {
    FooterContainer,
    FooterWrapper,
} from "./FooterElements";
import { FaTwitter, FaDiscord } from "react-icons/fa";

const Footer = () => {
    return (
        <>
            <FooterContainer id="foot">
                <FooterWrapper className="copy">
                    Suns Studio™ 2023 by <a href="https://twitter.com/rgb0x00">RGB</a>.
                </FooterWrapper>

                    {' '}<a className="socials" href="https://discord.gg/invite/RNSRHaxzbR"  target='blank'>
                    <FaDiscord />
                    </a>{' '}
                   <a className="socials" href="https://twitter.com/solsunsets" target='blank'><FaTwitter /></a>

            </FooterContainer>
        </>
    );
};

export default Footer;