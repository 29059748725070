//react scroll link by default
import styled from "styled-components";
import { Link } from "react-scroll";

export const Button = styled(Link)`
  width:300px;
  background: var(--bg-color);
  white-space: nowrap;
  padding: 8px 20px;
  color: var(--main-color);
  font-size: 28px;
  border: 2px solid var(--main-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: var(--main-color);
    color: var(--bg-color);
  }

  @media screen and (max-width: 480px) {
    width:270px;
    font-size: 20px;

    &:hover {
      background: var(--bg-color);
      color: var(--main-color);
    }


  }
`;

export const Group = styled.div`
display: flex;
margin-left: -70px;
padding-left: 0px;
color: var(--main-color);
background: var(--bg-color);

& p {
  rotate:-90deg;
  width: 40px;
  align-self: center;
  align-items: center;
  margin-top: 10%;

  @media screen and (max-width: 480px) {
    margin-top: 10px;
    padding-left: 0px;
    border-left: none;
  
  }
}

`;

export const BtnGroup = styled.div`
border-left: 4px solid var(--main-color);
padding-left: 30px;
margin-top: 16px;

& :last-child {
  margin-bottom: 0;}

@media screen and (max-width: 480px) {
  margin-top: 10px;
  padding-left: 10px;
}
`;
