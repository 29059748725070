import styled from "styled-components";

export const HeroContainer = styled.div``;

export const HeroContent = styled.div``;
export const HeroH1 = styled.h1``;

export const HeroH2 = styled.h2``;

export const HeroBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;

@media screen and (max-width: 480px) {
margin-bottom: 8px;
}

& :last-child {
  margin-bottom: 0;
}

& span {
  display: block;
  position: relative;

}
`;

export const BtnText = styled.div`
width: 250px;
text-align:center;
margin-left:20px;
`;

